// Imports
import Flickity from 'flickity-fade';
import Choices from 'choices.js';

const dropdownMenu = (() => {
	// Vars
	const menuChildren = document.querySelectorAll(
			'.menu__has-children .sub-menu'
		),
		menuHasChild = document.querySelectorAll('.menu__has-children');

	const initDropdownMenu = () => {
		__addHoverEvents();

		menuChildren.forEach((el) => {
			__setMaxHeight(el); // sets the needed height for each dropdown
			__toggleDescription(el);
		});
	};

	/*
		Adds/Remove active class when hovering over any elements that has a dropdown
	*/
	const __addHoverEvents = () => {
		menuHasChild.forEach((el, i, arr) => {
			el.addEventListener('mouseenter', function () {
				this.classList.add('menu__has-children__active');
			});

			el.addEventListener('mouseleave', function () {
				this.classList.remove('menu__has-children__active');
			});

			if (i === arr.length - 1)
				el.classList.add('menu__has-children__last');
		});
	};

	/*
		Traverses the dom on each element and finds the element with the highest height.
		Sets the highest height as the default height of the dropdown menu
	*/
	const __setMaxHeight = (el) => {
		const descriptionBlock = el.querySelectorAll('.menu__desc');
		let maxHeight = [];

		// Pushes height of the element to array
		maxHeight.push(el.offsetHeight);

		Array.prototype.map.call(descriptionBlock, (val) => {
			// Pushes height for each description block to the array
			maxHeight.push(val.offsetHeight);
		});

		console.log(maxHeight);

		// Finds the highest value in our height array.
		el.style.height = `${Math.max.apply(null, maxHeight).toString()}px`;
	};

	const __toggleDescription = (el) => {
		const hasDescription = el.querySelectorAll('.menu__desc--link');

		if (hasDescription.length > 0) {
			hasDescription[0].parentNode.classList.add('is-active');

			hasDescription.forEach(function (node) {
				node.addEventListener('mouseover', function (e) {
					e.preventDefault();

					for (let description of hasDescription) {
						description.parentNode.classList.remove('is-active');
					}

					this.parentNode.classList.add('is-active');
				});
			});
		}
	};

	return {
		init: initDropdownMenu,
	};
})();
document.addEventListener('DOMContentLoaded', dropdownMenu.init());

const mobileMenu = (() => {
	const mobileMenuTrigger = document.querySelector(
			'.masthead__toggle-mobile-menu'
		),
		bodyRoot = document.getElementsByTagName('body')[0],
		mobileMenuChildren = document.querySelectorAll(
			'.masthead__mobile-menu .menu-item-has-children'
		);

	const initMobileMenu = () => {
		if (mobileMenuTrigger) {
			__toggleMobileMenu(mobileMenuTrigger);
			__toggleSubMenu(mobileMenuChildren);
		}
	};

	const __toggleMobileMenu = (el) => {
		el.addEventListener('click', function () {
			bodyRoot.classList.toggle('mobile-menu-triggered');
			bodyRoot.classList.toggle('no-scroll');
		});
	};

	const __toggleSubMenu = (menuChildren) => {
		menuChildren.forEach(function (el) {
			el.addEventListener('click', function () {
				this.classList.toggle('is-active');
			});
		});
	};

	return {
		init: initMobileMenu,
	};
})();
mobileMenu.init();

const heroSlider = (() => {
	// Vars
	const heroSlider = document.querySelector('.c-hero'),
		slides = document.querySelectorAll('.c-hero__slide');

	const initSlider = () => {
		if (slides.length > 1) {
			const sliderOptions = {
				pageDots: true,
				prevNextButtons: false,
				draggable: true,
				autoPlay: 5000,
				fade: true,
				lazyLoad: true,
				pauseAutoPlayOnHover: false,
			};

			const slider = new Flickity(heroSlider, sliderOptions);
		}
	};

	return {
		init: initSlider,
	};
})();
heroSlider.init();

const contractsSlider = (() => {
	// Vars
	const contractsSlider = document.querySelector('.contracts__slider'),
		slides = document.querySelectorAll('.contracts__slide');

	const initSlider = () => {
		if (slides.length > 1) {
			const sliderOptions = {
				pageDots: true,
				prevNextButtons: false,
				draggable: true,
				autoPlay: false,
				contain: true,
				groupCells: '100%',
			};

			const slider = new Flickity(contractsSlider, sliderOptions);
		}
	};

	return {
		init: initSlider,
	};
})();
contractsSlider.init();

const testimonialSlider = (() => {
	// Vars
	const testimonialSlider = document.querySelector(
			'.testimonial-slider__init'
		),
		slides = document.querySelectorAll('.testimonial-slider__content');

	const initSlider = () => {
		if (slides.length > 1) {
			const sliderOptions = {
				pageDots: true,
				prevNextButtons: false,
				draggable: true,
				autoPlay: false,
			};

			const slider = new Flickity(testimonialSlider, sliderOptions);
		}
	};

	return {
		init: initSlider,
	};
})();
testimonialSlider.init();

const customSelect = (() => {
	//Vars
	const selectElement = document.querySelectorAll('.c-filters__select');
	const index = [];

	if (selectElement.length > 0) {
		selectElement.forEach((el, i) => {
			index[el.name] = new Choices(el, {
				searchEnabled: false,
				itemSelectText: '',
			});
		});

		// Disables Region Selector
		index['region'].disable();

		index['country'].passedElement.element.addEventListener(
			'choice',
			(e) => {
				e.detail.choice.value == 'england'
					? index['region'].enable()
					: index['region'].disable();
			}
		);
	}
})();

const ajaxPostFilters = (() => {
	// Vars
	const loadMore = document.querySelector('.js-load-more');

	const initFn = () => {
		if (loadMore) {
			__loadMore();
		}
	};

	const __loadMore = () => {
		// Vars
		const data = new URLSearchParams(),
			postWrapper = document.querySelector('#response'),
			postType = loadMore.getAttribute('data-post-type'),
			postsPerPage = loadMore.getAttribute('data-ppp'),
			taxonomies = {
				country: loadMore.getAttribute('data-country'),
				region: loadMore.getAttribute('data-region'),
			};

		let paged = 2;

		// Set Ajax Body Params
		data.set('action', 'archive__filter');
		data.set('postType', postType);
		data.set('nonce', wp_ajax.nonce);
		data.set('postsPerPage', postsPerPage);
		data.set('paged', paged);
		for (const key in taxonomies) data.set(key, taxonomies[key]);

		// Perform Ajax Request
		loadMore.addEventListener('click', function () {
			fetch(wp_ajax.url, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
				},
				credentials: 'same-origin',
				body: data,
			})
				.then((response) => {
					return response.json();
				})
				.then((response) => {
					if (response.data === '') {
						loadMore.textContent = 'Sorry, no more tenders..';
					} else {
						postWrapper.insertAdjacentHTML(
							'beforeend',
							response.data
						);
						paged++;
						data.append('paged', paged);
					}
				})
				.catch((error) => console.error(error));
		});
	};

	return {
		init: initFn,
	};
})();
ajaxPostFilters.init();

const tabs = (() => {
	const tabTrigger = document.querySelectorAll('.s-tabs__trigger');
	const tabContent = document.querySelectorAll('.s-tabs__content');

	tabTrigger.forEach((el, index) => {
		el.addEventListener('click', () => {
			const tabBgColor = el.parentNode.parentNode.parentNode.querySelector(
				'.s-tabs__bg--right'
			);
			const getTabAttribute = el.getAttribute('data-tab-trigger');
			const getTabContent = document.querySelector(
				`[data-tab-content='${getTabAttribute}']`
			);

			for (let [i, trigger] of tabTrigger.entries()) {
				trigger.classList.remove('is-active');
				trigger.classList.remove(`is-active-${i}`);
				tabBgColor.classList.remove(`s-tabs__bg--right-${i}`);
			}

			for (let [i, content] of tabContent.entries()) {
				content.classList.remove('is-active');
				content.classList.remove(`is-active-${i}`);
			}

			tabBgColor.classList.add(`s-tabs__bg--right-${index}`);
			el.classList.add('is-active');
			el.classList.add(`is-active-${index}`);
			getTabContent.classList.add(`is-active-${index}`);
			getTabContent.classList.add('is-active');
		});
	});
})();

const sidebarModal = (() => {
	const modalTrigger = document.querySelector('.sidebar-modal__trigger'),
		modalClose = document.querySelector('.sidebar-modal__close svg'),
		bodyRoot = document.getElementsByTagName('body')[0];

	const initSidebarModal = () => {
		if (modalTrigger) {
			__activateModal(modalTrigger);
			__closeModal(modalClose);
		}
	};

	const __activateModal = (el) => {
		el.addEventListener('click', function () {
			bodyRoot.classList.add('sidebar-modal-active');
			bodyRoot.classList.add('no-scroll');
		});
	};

	const __closeModal = (el) => {
		el.addEventListener('click', function () {
			bodyRoot.classList.remove('sidebar-modal-active');
			bodyRoot.classList.remove('no-scroll');
		});
	};

	return {
		init: initSidebarModal,
	};
})();
sidebarModal.init();

const WindowEvents = (() => {
	const html = document.getElementsByTagName('html')[0];
	const init = () => {
		__triggerWindowScrollEvent();
		document.addEventListener('scroll', __triggerWindowScrollEvent);
	};

	const __triggerWindowScrollEvent = () => {
		if (window.scrollY > 80) {
			!html.classList.contains('window-scrolled')
				? html.classList.add('window-scrolled')
				: false;
		} else {
			html.classList.contains('window-scrolled')
				? html.classList.remove('window-scrolled')
				: false;
		}
	};

	return {
		init: init,
	};
})();
WindowEvents.init();

jQuery(function ($) {
	const accordion = (() => {
		const initAccordion = () => {
			const accordion = $('.accordion');

			$.each(accordion, function () {
				const accordionTrigger = $(this).children(
					'.accordion__trigger'
				);
				const accordionContent = $(this).children(
					'.accordion__content'
				);

				accordionTrigger.on('click', () => {
					accordionContent.slideToggle('medium');
					$(this).toggleClass('is-active');
				});
			});
		};

		return {
			init: initAccordion,
		};
	})();
	accordion.init();

	/**************/
	/* Alert Block */
	/**************/

	var AlertPopup = (function () {
		var alert = $('.alert'),
			init = () => {
				if (
					(alert.length &&
						localStorage.getItem('phs_alert') === null) ||
					(alert.length && _is_urgent())
				) {
					_setHeight();
					_showAlert();
					_bindEvents();
				}
			},
			_bindEvents = () => {
				$('.alert').on(
					'click touchstart',
					'.alert__close--js',
					_closeAlert
				);
				$(window).on('resize', _setHeight);
			},
			_is_urgent = () => {
				if (alert.hasClass('js-alert--urgent')) return true;
				return false;
			},
			_showAlert = () => {
				if (_checkForLocalStorage() && !_is_urgent()) {
					localStorage.setItem('phs_alert', true);
				}
				if (_is_urgent()) {
					localStorage.removeItem('phs_alert');
				}
				$('body').addClass('alert-visible');
			},
			_setHeight = () => {
				var alert_box = alert.find('.alert__container');
				var alert_image = alert.find('.alert__image').outerHeight();
				var alert_content = alert.find('.alert__content').outerHeight();
				if (alert_box.length) {
					if (alert_image) {
						alert_box.height(alert_image + alert_content);
					} else {
						alert_box.height(alert_content);
					}
				}
			},
			_closeAlert = (e) => {
				e.preventDefault();
				$('body').removeClass('alert-visible');
			},
			_checkForLocalStorage = () => {
				var test = 'test';
				try {
					localStorage.setItem(test, test);
					localStorage.removeItem(test);
					return true;
				} catch (e) {
					return false;
				}
			};
		return {
			init: init,
		};
	})();
	AlertPopup.init();
});
